@import 'assets/styles/_mixins.scss';

.getInTouch {
  margin-left: -2px;
  margin-top: 119px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 69px;

  &_title {
    font-weight: 700;
    font-size: 90px;
    line-height: 80%;
    text-align: center;
    letter-spacing: -0.03em;

    @include maxWidth(900) {
      font-weight: 700;
      font-size: 48px;
      line-height: 80%;
    }

    @include maxWidth(450) {
      line-height: 79%;
    }
  }

  &__button {
    padding: 17.8402px 19.8224px 18.8313px;
    background: $blue-secondary;

    font-weight: 700;
    font-size: 35.1464px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: $white;
    border-radius: 8.38px;
    transition: ease 0.2s;
    &:hover {
      background: $blue;
    }

    @include maxWidth(900) {
      font-weight: 700;
      font-size: 21px;
      line-height: 19px;
    }

    @include maxWidth(450) {
      padding: 11px 30px;
      border-radius: 5px;
    }
  }

  @include maxWidth(900) {
    margin-top: 65px;
    gap: 31px;
  }
}
