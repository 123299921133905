@import './assets/styles/_mixins.scss';

.faq_list {
  margin-top: 88px;
  display: flex;
  flex-direction: column;
  gap: 34px;

  @include maxWidth($tablet-s) {
    margin-top: 60px;
    gap: 28px;
  }

  @include maxWidth($mobile-l) {
    margin-top: 40px;
    gap: 22px;
  }

  &__item {
    gap: 48px;

    &_content {
      margin: 15px 48px 8px 0;

      & p {
        margin-bottom: 15px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
