@import './assets/styles/mixins';

.game_page {
  position: relative;
  padding-top: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__container__first {
    max-width: 1351px;
  }

  &__container__second {
    max-width: 847px;
  }

  &__container__third {
    margin-top: 148px;

    @include maxWidth($tablet-s) {
      margin-top: 59px;
    }
  }

  &__content {
    width: 100%;
    margin-top: 58px;
    text-align: left;

    @include maxWidth($mobile-xl) {
      margin-top: 40px;
    }

    & p {
      min-height: 26px;
    }

    &_title {
      margin-bottom: 65px;
      opacity: 0.7;
    }

    & h1 {
      @include h1;
    }

    & h2 {
      @include h2;
    }

    & h3 {
      @include h3;

      @include maxWidth($mobile-xl) {
        @include h2;
      }
    }

    & h4 {
      @include h4;
    }

    & p {
      @include p1;
    }

    @include maxWidth($tablet-s) {
      iframe {
        height: 51vw;
      }
    }
  }

  &__divider {
    top: 305px;
    height: 1154px;
    background: linear-gradient(
      180deg,
      #000000 0%,
      #04005f 18.23%,
      #350048 32.81%,
      rgba(78, 0, 125, 0.24) 56.77%,
      rgba(6, 1, 255, 0.53) 64.58%,
      rgba(0, 0, 0, 0.49) 82.29%,
      rgba(3, 1, 130, 0.18) 89.58%,
      rgba(0, 0, 0, 0) 100%
    );
    transform: translateX(-50%) matrix(-1, 0, 0, 1, 0, 0);

    @include maxWidth($tablet-l) {
      display: none;
    }
  }

  &__category {
    gap: 12.61px;
    margin-top: 45px;
    background: transparent;

    @include maxWidth($tablet-s) {
      padding: 0;
    }

    @include maxWidth($mobile-xl) {
      margin-top: 20px;
    }

    img {
      @include maxWidth($tablet-s) {
        width: 20px;
      }

      @include maxWidth($mobile-xl) {
        width: 17px;
      }
    }
  }

  &__similar_games {
    width: 100%;
  }
}
