$font-main: 'Monument Grotesk', 'Helvetica', 'Times New Roman';

$common-layout-width: 1620px;

//colors
$white: #fff;
$secondary-white: #fbfbfb;
$white-80: #ffffff80;
$light-gray: #fafafa;
$light-white: #f3f3f3;
$gray-background-light: #e4e4e4;
$black: #000;
$black-light: #222222;
$secondary-black: #161616;
$gray-text: #5b5b5b;
$secondary_gray_text: #4e4e4e;
$gray-background: #252525;
$gray-background-secondary: #303030;
$gray-medium: #b2b2b2;
$gray-1: #343434;
$gray-2: #3d3d3d;
$placeholder-color: #7d7d7d;
$red: #610004;
$red-2: #f40202f0;
$blue: #0041ff;
$blue-secondary: #0500e9;
$blue-3: #0601ff;
$blue-4: #170fac;
$blue-secondary-ligth: #0598d4;
$blue-secondary-dark: #28058b;

$blue-dark: #00071a;
$yellow: #ffe500;

// breakpoints
$mobile-s: 325;
$mobile-m: 375;
$mobile-l: 440;
$mobile-xl: 575;
$tablet-s: 768;
$tablet-m: 820;
$tablet-l: 1024;
$desktop-s: 1280;
$desktop-m: 1440;
$desktop-l: 1680;
$desktop-xl: 1920;
$desktop-wide: 2560;

// JS variables
:export {
  $brandColor: $blue;
}
