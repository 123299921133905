@import './assets/styles/mixins';

.background_divider {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  bottom: -350px;
  filter: blur(10px);
  width: 104vw;
  height: 538px;
  background: linear-gradient(
    180deg,
    #000000 0%,
    #04005f 16.15%,
    #350048 23.71%,
    rgba(78, 0, 125, 0.24) 36.46%,
    rgba(6, 1, 255, 0.53) 44.96%,
    rgba(0, 0, 0, 0.49) 71.57%,
    rgba(3, 1, 130, 0.18) 82.81%,
    rgba(0, 0, 0, 0) 100%
  );

  @include maxWidth($mobile-xl) {
    bottom: -252px;
    height: 400px;
  }
}
