@import './vars/scss-variables';

@mixin maxWidth($breakpoint) {
  @media only screen and (max-width: $breakpoint + 'px') {
    @content;
  }
}

@mixin maxHeight($breakpoint) {
  @media only screen and (max-height: $breakpoint + 'px') {
    @content;
  }
}

@mixin minWidth($breakpoint) {
  @media only screen and (min-width: $breakpoint + 'px') {
    @content;
  }
}

@mixin minMaxWidth($breakpoint-min, $breakpoint-max) {
  @media only screen and (min-width: $breakpoint-min + 'px') and (max-width: $breakpoint-max + 'px') {
    @content;
  }
}

@mixin noTouch {
  @media not all and (-moz-touch-enabled: 1),
    not all and (pointer: coarse),
    not all and (-ms-high-contrast: none) and (max-width: 600px) {
    @content;
  }
}

@mixin noTouchHover {
  @media not all and (-moz-touch-enabled: 1),
    not all and (pointer: coarse),
    not all and (-ms-high-contrast: none) and (max-width: 600px) {
    &:hover {
      @content;
    }
  }
}

@mixin touchDevices {
  @media (-moz-touch-enabled: 1),
    (pointer: coarse),
    (-ms-high-contrast: none) and (max-width: 600px) {
    @content;
  }
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min-resolution: 1.5dppx),
    only screen and (min-resolution: 144dpi) {
    @content;
  }
}

@mixin scrollBar($size: 3px, $barColor: $white-80, $thumbColor: $blue, $radius: 7px) {
  scrollbar-color: $thumbColor $barColor;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: $barColor;
    width: $size;
    height: $size;
    border-radius: $radius;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $radius;
    background-color: $thumbColor;
  }
}

@mixin mainPageAnimatedCard {
  position: relative;
  height: 512px;
  max-width: 373px;
  transform: scale(0.97);
  border-radius: 30px;
  overflow: hidden;
  transition: 0.26s;
  z-index: 1;
  cursor: pointer;
  will-change: transform;

  @include maxWidth($desktop-xl) {
    max-width: unset;
  }

  @include maxWidth($desktop-l) {
    max-width: 373px;
  }

  @include maxWidth($desktop-m) {
    height: 430px;
  }

  @include maxWidth($tablet-l) {
    height: 380px;
    border-radius: 24px;
  }

  @include maxWidth(940) {
    height: 340px;
  }

  @include maxWidth($tablet-s) {
    height: 380px;
    border-radius: 30px;
  }

  @include maxWidth($mobile-m) {
    height: 341px;
    max-width: 248px;
    border-radius: 20.5px;
  }

  @include maxWidth($mobile-s) {
    max-width: unset;
  }

  &__loaded {
    &:hover {
      transform: scale(1);
    }
  }
}

@mixin mainPageAnimatedCardTitle {
  text-align: left;
  position: absolute;
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.02em;
  left: 26px;
  top: 27px;
  z-index: 65;

  @include maxWidth($desktop-m) {
    font-size: 25px;
  }

  @include maxWidth($tablet-l) {
    left: 18px;
    top: 18px;
    font-size: 22px;
  }

  @include maxWidth(450) {
    left: 13px;
    top: 21px;
    font-size: 22px;
    line-height: 100%;
  }

  @include maxWidth($mobile-m) {
    font-size: 20.5996px;
    left: 19px;
    top: 18px;
  }
}

@mixin mainPageAnimatedCardActions {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 4%;
  opacity: 0;
  z-index: 90;
  padding: 0 12px;
  transition: 0.3s;

  &_button {
    cursor: pointer;
    background: $blue-secondary;
    border-radius: 6.94515px;
    padding: 22px 24px;
    font-weight: 600;
    font-size: 38px;
    line-height: 41px;
    text-align: center;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    transition: ease 0.2s;
    color: $white;

    &:hover {
      background: $blue;
    }

    @include maxWidth($desktop-m) {
      padding: 16px 20px;
      font-size: 30px;
      line-height: 34px;
    }

    @include maxWidth($tablet-l) {
      bottom: 6%;
      padding: 8px 16px;
      font-size: 22px;
      line-height: 29px;
    }

    @include maxWidth($mobile-l) {
      padding: 10px 18px;
      font-size: 24px;
    }
  }
}

@mixin h1 {
  font-weight: 700;
  font-size: 90px;
  line-height: 81px;
  letter-spacing: -0.03em;

  @include maxWidth($desktop-s) {
    font-size: 70px;
    line-height: 60px;
  }

  @include maxWidth($tablet-l) {
    font-size: 60px;
    line-height: 55px;
  }

  @include maxWidth($mobile-xl) {
    font-size: 48px;
    line-height: 45px;
  }
}

@mixin h2 {
  font-weight: 700;
  font-size: 74px;
  line-height: 70px;
  letter-spacing: -0.03em;

  @include maxWidth($desktop-s) {
    font-size: 60px;
    line-height: 60px;
  }

  @include maxWidth($tablet-l) {
    font-size: 50px;
    line-height: 50px;
  }

  @include maxWidth($mobile-xl) {
    font-size: 36px;
    line-height: 35px;
  }
}

@mixin h3 {
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -0.03em;

  @include maxWidth($desktop-s) {
    font-size: 35px;
    line-height: 44px;
  }

  @include maxWidth($tablet-l) {
    font-size: 30px;
  }

  @include maxWidth($mobile-xl) {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
  }

  @include maxWidth($mobile-l) {
    font-size: 18px;
    line-height: 24px;
  }
}

@mixin h4 {
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.02em;

  @include maxWidth($desktop-s) {
    font-size: 26px;
  }

  @include maxWidth($tablet-l) {
    font-size: 20px;
  }

  @include maxWidth($mobile-xl) {
    font-size: 16px;
    letter-spacing: -0.02em;
  }
}

@mixin p1 {
  font-weight: 400;
  font-size: 24px;
  line-height: 110%;

  @include maxWidth($tablet-l) {
    font-size: 20px;
  }

  @include maxWidth($mobile-xl) {
    font-size: 16px;
    line-height: 140%;
  }

  @include maxWidth($mobile-m) {
    font-size: 13px;
  }
}

@mixin p2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;

  @include maxWidth($mobile-xl) {
    font-size: 13px;
    line-height: 140%;
  }

  @include maxWidth($mobile-m) {
    font-size: 11px;
    line-height: 130%;
  }
}
