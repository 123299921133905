@import './assets/styles/mixins';

.vacancies {
  display: flex;
  flex-direction: column;
  margin-top: 49px;
  gap: 54px;

  @include maxWidth($tablet-l) {
    margin-top: 42px;
    gap: 44px;
  }

  @include maxWidth($mobile-xl) {
    margin-top: 36px;
    gap: 38px;
  }

  @include maxWidth($mobile-l) {
    margin-top: 20px;
    gap: 16px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include maxWidth($tablet-l) {
      gap: 15px;
    }

    @include maxWidth($mobile-l) {
      gap: 10px;
    }

    &_list {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @include maxWidth($tablet-l) {
        gap: 15px;
      }

      @include maxWidth($mobile-l) {
        gap: 16px;
      }
    }
  }
}
