@import './assets/styles/mixins';

.career_page {
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  &__sections {
    max-width: 911px;
    margin-left: 50%;
    transform: translateX(-48%);
    //design layout is incorrect

    @include maxWidth($desktop-s) {
      transform: none;
      margin: 0 auto;
    }
  }
}
