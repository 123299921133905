@import 'assets/styles/_mixins.scss';

.text {
  display: flex;
  flex-direction: column;
  gap: 34px;
  position: relative;
  z-index: 10;
  max-width: 944px;
  margin-top: 110px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: -0.03em;
  color: $gray-text;
  gap: 90px;

  @include maxWidth($desktop-s) {
    font-size: 34px;
    line-height: 140%;
  }

  @include maxWidth($tablet-l) {
    margin-top: 70px;
    font-size: 30px;
  }

  @include maxWidth(900) {
    margin-top: 60px;
    font-size: 24px;
  }

  @include maxWidth($mobile-xl) {
    margin-top: 45px;
    font-size: 18px;
    gap: 23px;
  }

  span {
    color: $white;
    font-weight: 700;
  }

  &_actions {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 11px;
    transition: 0.25s;

    &:hover {
      & svg {
        & rect {
          fill: #fff;
        }
      }
    }

    span {
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: $gray-text;
      transition: 0.25s;

      @include maxWidth($tablet-l) {
        font-weight: 500;
        font-size: 28px;
        line-height: 140%;
      }

      @include maxWidth($mobile-xl) {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
      }
    }

    &:hover {
      gap: 18px;

      span {
        color: $white;
      }

      .arrow {
        background: $white;
      }
    }

    @include maxWidth(450) {
      gap: 6px;
    }
  }
}

.about_company {
  z-index: -1;
}

.circle_icon {
  display: flex;
  transition: transform 0.25s;

  @include maxWidth($mobile-xl) {
    width: 18.38px;
    height: 18.38px;
  }
}
