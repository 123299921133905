@import './assets/styles/_mixins.scss';

.accordeon {
  position: relative;

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    color: $white;

    @include maxWidth($tablet-s) {
      font-size: 18px;
      line-height: 20px;
    }

    @include maxWidth($mobile-xl) {
      font-size: 16px;
      line-height: 18px;
    }

    @include maxWidth($mobile-l) {
      font-size: 13px;
    }
  }

  &__arrow {
    transition: transform 0.4s ease;
    flex-shrink: 0;
    width: 27px;
    height: 14px;

    @include maxWidth($mobile-xl) {
      width: 22px;
      height: 12px;
    }

    &__active {
      transform: rotate(180deg);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    cursor: pointer;

    @include maxWidth($mobile-l) {
      gap: 26px;
    }
  }

  &__content {
    margin-top: 15px;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: $gray-medium;
    gap: 13px;

    & h1 {
      @include h1;
    }

    & h2 {
      @include h2;
    }

    & h3 {
      @include h3;
    }

    & h4 {
      @include h4;
    }

    & a {
      color: $white;
      transition: color 0.2s;
      border-bottom: 2px solid $blue;

      &:hover {
        color: $blue;
      }
    }

    & p {
      @include p1;
      min-height: 26.33px;
      line-height: 31px;
      color: $gray-medium;
    }

    & li {
      @include p1;
      position: relative;
      color: $gray-medium;

      &::before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $blue;
        position: absolute;
        left: -30px;
        top: 6px;

        @include maxWidth($mobile-xl) {
          top: 5px;
          left: -20px;
          width: 10px;
          height: 10px;
        }
      }
    }

    & ul {
      width: 71.5%;
      margin-left: 84px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;

      @include maxWidth($mobile-xl) {
        width: calc(100% - 20px);
        margin-left: 20px;
      }
    }

    @include maxWidth($tablet-s) {
      font-size: 18px;
      line-height: 20px;
    }

    @include maxWidth($mobile-xl) {
      font-size: 16px;
      line-height: 18px;
    }

    @include maxWidth($mobile-l) {
      font-size: 13px;
    }

    &_wrapper {
      opacity: 0;
      max-height: 0;
      transform: scale(0.4);
      transform-origin: top;
      transition: 0.4s ease;
      overflow: hidden;

      &__active {
        opacity: 1;
        transform: scale(1);
        max-height: fit-content;
      }
    }
  }
}
