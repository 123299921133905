@import './assets/styles/mixins';

.vacancies_section {
  max-width: 760px;
  margin: 0;
  box-sizing: content-box;

  &_empty {
    margin-top: 84px !important;
    align-items: flex-start !important;

    @include maxWidth($mobile-m) {
      margin: 31px 0 153px !important;
    }

    & p {
      @include h3;
      max-width: 590px;
      display: flex;
      text-align: start;

      &:first-child {
        margin-bottom: 22px;

        @include maxWidth($mobile-m) {
          margin-bottom: 14px;
        }
      }

      @include maxWidth($tablet-l) {
        max-width: 400px;
      }

      @include maxWidth($mobile-m) {
        max-width: 98%;
      }
    }

    &__button {
      @include maxWidth($mobile-m) {
        width: 100%;
      }
    }
  }

  &__title {
    text-align: center;
  }

  &__counter {
    width: fit-content;
    margin: 48px auto 0;
    display: flex;
    justify-content: center;
    padding: 1px 16px 5px;
    border-radius: 50px;
    background: $blue;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    color: $white;

    @include maxWidth($tablet-l) {
      margin: 40px auto 0;
      padding: 1px 16px 5px;
      border-radius: 50px;
      font-size: 24px;
      line-height: 130%;
    }

    @include maxWidth($mobile-l) {
      margin: 20px auto 0;
      padding: 4.5px 16px 6.5px;
      border-radius: 50px;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
