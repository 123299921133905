@import './assets/styles/mixins';

.faq_section {
  margin: 137px 0 0;
  max-width: 831px;
  box-sizing: content-box;

  @include maxWidth($tablet-s) {
    margin-top: 90px;
  }

  @include maxWidth($mobile-l) {
    margin-top: 64px;
  }

  &__title {
    text-align: center;
    @include maxWidth($tablet-s) {
      text-align: left;
    }
  }
}
