@import './assets/styles/mixins';

.vacancy__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $gray-text;
  transition: color 0.25s;
  cursor: pointer;

  &:hover {
    color: $white;

    & .vacancy__item_arrow {
      transform: translateX(7px);
    }
  }

  &_arrow {
    transition: transform 0.25s;
    display: flex;

    @include maxWidth($mobile-xl) {
      width: 18.38px;
      height: 18.38px;
    }
  }
}
