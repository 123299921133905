@import './assets/styles/mixins';

.games_page {
  margin-top: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include maxWidth($mobile-xl) {
    padding: 0 15px;
  }

  &__title {
    margin-bottom: 35px;
  }

  &__game_item {
    max-width: 271.46px;

    @include maxWidth($desktop-m) {
      max-width: 255px;
    }

    @include maxWidth($desktop-s) {
      width: calc(25% - 30px);
    }

    @include maxWidth($tablet-s) {
      width: calc(33% - 26px);
    }

    @include maxWidth($mobile-xl) {
      width: calc(33% - 18px);
    }

    @include maxWidth($mobile-l) {
      width: calc(50% - 18px);
    }
  }

  &__games_list {
    @include maxWidth($tablet-l) {
      gap: 26px;
    }

    @include maxWidth($mobile-xl) {
      gap: 18px;
    }
  }
}

.swiper-pagination {
  position: relative !important;
  margin-top: 0 !important;
  bottom: 40px;
}
